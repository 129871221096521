import React from "react";
import { Box, Typography, Divider, makeStyles, Tabs, Tab, } from "@material-ui/core";
import TabPanel from "../../molecules/TabPanel";
import VaultTable from "../../molecules/VaultTable";
import theme from "../../../utils/theme";
import PolicyCard from "../../molecules/PolicyCard";
import { useVault } from "./hook";
var useStyles = makeStyles(function (theme) { return ({
    divider: {
        background: theme.palette.grey[300],
    },
    tabsRoot: {
        minHeight: "28px",
        marginBottom: "30px",
    },
    indicator: {
        backgroundColor: "transparent",
    },
    tabRoot: {
        padding: "0px",
        borderRadius: "15px",
        minHeight: "28px",
        width: "20px",
        textTransform: "none",
    },
}); });
function MainContent() {
    var classes = useStyles();
    var _a = useVault(), loading = _a.loading, tableRows = _a.tableRows, marketingRows = _a.marketingRows, supportRows = _a.supportRows, error = _a.error, tableTab = _a.tableTab, setTableTab = _a.setTableTab;
    return (React.createElement(Box, { display: "block", width: "1080px", marginTop: "12px", justifyContent: "space-around" },
        React.createElement(Tabs, { classes: {
                root: classes.tabsRoot,
                indicator: classes.indicator,
            }, value: tableTab, onChange: function (e, value) { return setTableTab(value); }, textColor: "primary", centered: true },
            React.createElement(Tab, { style: {
                    background: tableTab == 0 ? theme.palette.primary.main : "white",
                    color: tableTab == 0 ? "white" : theme.palette.text.secondary,
                    border: tableTab == 0 ? "none" : "1px solid #5e6c84",
                }, classes: { root: classes.tabRoot }, label: "Admin", value: 0 }),
            React.createElement(Tab, { style: {
                    background: tableTab == 1 ? theme.palette.primary.main : "white",
                    border: tableTab == 1 ? "none" : "1px solid #5e6c84",
                    color: tableTab == 1 ? "white" : theme.palette.text.secondary,
                    marginLeft: "8px",
                }, classes: { root: classes.tabRoot }, label: "Marketing", value: 1 }),
            React.createElement(Tab, { style: {
                    background: tableTab == 2 ? theme.palette.primary.main : "white",
                    color: tableTab == 2 ? "white" : theme.palette.text.secondary,
                    border: tableTab == 2 ? "none" : "1px solid #5e6c84",
                    marginLeft: "8px",
                }, classes: { root: classes.tabRoot }, label: "Customer Support", value: 2 })),
        React.createElement(Box, { style: {
                height: "138px",
                justifyContent: "center",
                marginBottom: "18px",
                border: "2px solid",
                borderColor: "#dfe3eb",
                borderRadius: "4px",
            } },
            React.createElement(TabPanel, { value: tableTab, index: 0 },
                React.createElement(Box, { width: "940px", paddingTop: "18px", paddingLeft: "58px", paddingRight: "58px" },
                    React.createElement(Typography, { variant: "h5", color: "textPrimary", style: { fontWeight: "bold" } }, "Roles & Policies"),
                    React.createElement(Typography, { variant: "body2", color: "textPrimary" },
                        "Skyflow\u2019s governance engine lets you create ",
                        React.createElement("b", null, "roles"),
                        " for each of your data consumers - human users or downstream applications. You can attach granular ",
                        React.createElement("b", null, "policies"),
                        " to each role that define which data that role has access to, in what format, and subject to which conditions. The Admin role has a single policy attached (shown below) which enables data consumers with the Admin role to read all columns in the Payments table in plain text."))),
            React.createElement(TabPanel, { value: tableTab, index: 1 },
                React.createElement(Box, { width: "940px", paddingTop: "18px", paddingLeft: "58px", paddingRight: "58px" },
                    React.createElement(Typography, { variant: "h5", color: "textPrimary", style: { fontWeight: "bold" } }, "Dynamic Redaction"),
                    React.createElement(Typography, { variant: "body2", color: "textPrimary" },
                        "Through policies you can specify how individual columns should be redacted upon retrieval. The policy below fully ",
                        React.createElement("b", null, "redacts"),
                        " the SSN column and ",
                        React.createElement("b", null, "masks"),
                        " (partially redacts) the year in the Date of Birth column for data consumers with the Marketing role. This ensures that each data consumer has exactly right the amount of information needed for their function."))),
            React.createElement(TabPanel, { value: tableTab, index: 2 },
                React.createElement(Box, { width: "940px", paddingTop: "18px", paddingLeft: "58px", paddingRight: "58px" },
                    React.createElement(Typography, { variant: "h5", color: "textPrimary", style: { fontWeight: "bold" } }, "Row-level policies"),
                    React.createElement(Typography, { variant: "body2", color: "textPrimary" }, "You can also use policies to enforce granular access to individual rows in addition to columns. Suppose you wanted to grant a customer support rep in Arizona access to only the data of Arizona users. The policy below grants differentially redacted access to user data for rows where the \u201Cstate\u201D column is set to \u201CArizona\u201D.")))),
        React.createElement(Box, { display: "flex" },
            React.createElement(Box, { width: "50%", alignContent: "left" },
                React.createElement(TabPanel, { value: tableTab, index: 0 },
                    React.createElement(Typography, { variant: "body2", color: "textPrimary", style: { marginTop: "12px", textAlign: "center" } }, "Policies for the Admin role"),
                    React.createElement(PolicyCard, { key: "1", title: "Admin policy", assignedRoles: ["Admin role"], tableTab: 0, policyText: [
                            "ALLOW READ ON payments.* WITH REDACTION = PLAIN_TEXT",
                        ] })),
                React.createElement(TabPanel, { value: tableTab, index: 1 },
                    React.createElement(Typography, { variant: "body2", color: "textPrimary", style: { marginTop: "12px", textAlign: "center" } }, "Policies for the Marketing role"),
                    React.createElement(PolicyCard, { key: "2", title: "Marketing policy", assignedRoles: ["Marketing role"], tableTab: 1, policyText: [
                            "ALLOW READ ON payments.name,payments.email, payments.state WITH REDACTION = PLAIN_TEXT",
                            "ALLOW READ ON payments.ssn WITH REDACTION = REDACTED",
                            "ALLOW READ ON payments.date_of_birth WITH REDACTION = MASKED",
                        ] })),
                React.createElement(TabPanel, { value: tableTab, index: 2 },
                    React.createElement(Typography, { variant: "body2", color: "textPrimary", style: { marginTop: "12px", textAlign: "center" } }, "Policies for the Customer Support role"),
                    React.createElement(PolicyCard, { key: "3", title: "Customer Support policy", assignedRoles: ["Customer Support role"], tableTab: 2, policyText: [
                            "ALLOW READ ON payments.name,payments.state WITH REDACTION = PLAIN_TEXT WHERE payments.state = Arizona",
                            "ALLOW READ ON payments.ssn WITH REDACTION = MASKED WHERE payments.state = Arizona",
                            "DENY READ ON payments.* WITH REDACTION = REDACTED WHERE payments.state ! = Arizona",
                        ] }))),
            React.createElement(Divider, { style: { margin: "12px 24px 0px 24px" }, classes: { root: classes.divider }, orientation: "vertical", flexItem: true }),
            React.createElement(Box, { width: "50%", alignContent: "left" },
                React.createElement(TabPanel, { value: tableTab, index: 0 },
                    React.createElement(Typography, { variant: "body2", color: "textPrimary", style: { marginTop: "12px", textAlign: "center" } }, "Data view for the Admin role")),
                React.createElement(TabPanel, { value: tableTab, index: 1 },
                    React.createElement(Typography, { variant: "body2", color: "textPrimary", style: { marginTop: "12px", textAlign: "center" } }, "Data view for the Marketing role")),
                React.createElement(TabPanel, { value: tableTab, index: 2 },
                    React.createElement(Typography, { variant: "body2", color: "textPrimary", style: { marginTop: "12px", textAlign: "center" } }, "Data view for the Customer Support role")),
                React.createElement(VaultTable, { highlightColumnCells: tableTab === 1 ? [2, 3] : [], rows: tableTab === 0
                        ? tableRows
                        : tableTab === 1
                            ? marketingRows
                            : supportRows, columnName: ["name", "email", "ssn", "date_of_birth", "state"], error: error, loading: loading, tableTab: tableTab })))));
}
export default MainContent;
